<template>
  <!-- eslint-disable  -->
  <div>
    <v-card flat class="w100p">
      <!--  -->
    </v-card>
    <div class="d-sm-flex mb-3 text-Heading6 Medium d-none">
      {{ $store.getters['app/getRouteTitle'] }}
      <v-spacer></v-spacer>
    </div>

    <v-card
      outlined
      :color="$vuetify.breakpoint.smAndDown ? 'transparent' : null"
    >
      <v-card-text class="d-flex align-center pb-0 pb-sm-4">
        <v-spacer></v-spacer>
        <RefreshBtn :fetchData="fetchData" :loading="isLoading" />

        <SizeBox width="14" />
        <v-btn
          rounded
          color="primary"
          dark
          depressed
          @click="openForm('Info', null, 'new')"
          :disabled="!$getGrantValue('black_list_collect_order_list.add')"
        >
          新增
        </v-btn>
        <SizeBox width="14" />
        <ExportBtn
          :items="result.list"
          :headers="headers"
          :file-name="`${
            $store.getters['app/getRouteTitle']
          } ${$moment().format('YYYY-MM-DD_H:mm:ss')}`"
          :labels="labels"
          sheet-name="sheetName"
          :exclude="['actions']"
        />
      </v-card-text>
      <DataTable
        :list="result.list"
        :total="result.total"
        :fields="headers"
        :labels="labels"
        :replace="['actions', 'created_at|formatTime']"
        @get-list="fetchData"
      >
        <template #item.actions="{ item }">
          <div class="flex-center">
            <v-menu>
              <template #activator="{ on }">
                <v-btn
                  v-on="on"
                  color="Secondary100"
                  rounded
                  :small="$vuetify.breakpoint.smAndDown"
                >
                  操作
                  <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-list rounded>
                <v-list-item
                  v-for="(key, i) in item.actions"
                  v-show="!(key.hide && key.hide(item))"
                  :key="`key:${item.id} ${i}`"
                  @click="
                    key.action && handleAction((vm) => key.action(vm, item))
                  "
                  :disabled="key.grant ? !$getGrantValue(key.grant) : false"
                >
                  <v-icon v-if="!!key.icon" size="18" class="mr-1">
                    {{ key.icon }}
                  </v-icon>
                  {{ key.label }}
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </template>
      </DataTable>
    </v-card>

    <InfoForm
      :value="showingForm === 'Info'"
      @close="showingForm = null"
      :passData="passData"
      @save="fetchData"
    />
    <FunctionFab
      @info="isInfoOpen = true"
      @refresh="fetchData()"
      @open-filter="isFilterOpen = true"
      :no-filter="!filterOptions.length"
      :no-info="!Object.entries(result.header_info).length"
    />
  </div>
</template>

<script>
import {
  deleteCollectBlacklist,
  getCollectBlacklists
} from '@/api/collectBlacklists';

import InfoForm from '@/components/collect/black-list/InfoForm.vue';

const labels = {
  type: [
    {
      color: 'Error200',
      text: 'IP',
      value: 'ip'
    },
    {
      color: 'Alert200',
      text: '付款人',
      value: 'payer_name'
    }
  ]
};

const actions = [
  {
    label: '編輯',
    icon: 'mdi-filter-variant',
    grant: 'black_list_collect_order_list.edit',
    action: (vm, v) => {
      vm.openForm('Info', v, 'edit');
    }
  },
  {
    label: '刪除',
    icon: '',
    grant: 'black_list_collect_order_list.remove',
    action: (vm, v) => {
      vm.$confirm({
        title: '刪除',
        content: '刪除之後，資料將無法復原，您確定要刪除？',
        btn: {
          color: 'error',
          text: '刪除'
        },
        callback: async () => {
          await deleteCollectBlacklist({ id: v.id });
          vm.$toast('刪除成功');
          vm.fetchData();
        }
      });
    }
  }
];

export default {
  components: {
    InfoForm
  },
  data() {
    return {
      ...this.$clone(this.$commonSchema),

      labels,
      headers: [
        { text: '操作', value: 'actions', align: 'center' },
        { text: '類型', value: 'type', align: 'center' },
        { text: '阻擋內容', value: 'content', align: 'center' },
        { text: '備註', value: 'note', align: 'center' },
        { text: '建立時間', value: 'created_at', align: 'center' }
      ]
    };
  },

  created() {
    this.fetchData();
  },

  methods: {
    async fetchData(condition = this.condition) {
      this.isLoading = true;
      this.condition = condition;
      const Orders = await getCollectBlacklists({
        ...this.filter,
        ...condition
      });
      if (!Orders.error) {
        this.result.total = Orders.total;
        this.result.page = Orders.page;
        this.$set(
          this.result,
          'list',
          Orders.items.map((item) => ({
            ...item,
            actions
          }))
        );
      }

      this.isLoading = false;
    },
    openForm(name, data = {}, mode = '') {
      this.showingForm = name;

      this.$set(this, 'passData', {
        mode,
        ...data
      });
    },
    handleAction(fn) {
      fn(this);
    }
  }
};
</script>

<style lang="scss" scoped>
//
</style>
