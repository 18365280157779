import { api } from '.';

export function getCollectBlacklists(payload) {
  return api('/v1/collectBlacklists/getCollectBlacklists', payload);
}
export function createCollectBlacklist(payload) {
  return api('/v1/collectBlacklists/createCollectBlacklist', payload);
}
export function updateCollectBlacklist(payload) {
  return api('/v1/collectBlacklists/updateCollectBlacklist', payload);
}
export function deleteCollectBlacklist(payload) {
  return api('/v1/collectBlacklists/deleteCollectBlacklist', payload);
}
